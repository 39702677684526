import * as L from "leaflet";

export default function singleCarto() {
  const marker = jQuery(".marker");
  const marker_content = marker.html();
  const mymap = L.map("single-carte", { scrollWheelZoom: false }).setView(
    [marker.data("lat"), marker.data("lng")],
    16
  );
  const layerGroup = L.layerGroup().addTo(mymap);
  L.tileLayer(
    "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidmVybmFsaXMiLCJhIjoiY2p4eGVsb2JuMDVidjNtbXYxYmVxMWhpdyJ9.TTdCkw78ypJhymWFRXlDQQ",
    {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 21,
      id: "mapbox/streets-v11",
      tileSize: 512,
      zoomOffset: -1,
      accessToken:
        "pk.eyJ1IjoidmVybmFsaXMiLCJhIjoiY2p4eGVsb2JuMDVidjNtbXYxYmVxMWhpdyJ9.TTdCkw78ypJhymWFRXlDQQ",
    }
  ).addTo(mymap);

  var marker_carte = L.marker([marker.data("lat"), marker.data("lng")]).addTo(
    mymap
  );
  marker_carte.bindPopup(marker_content);
  jQuery(".change-view").on("click", function () {
    jQuery(".change-view").each(function () {
      if (jQuery(this).hasClass("active")) {
        jQuery(this).removeClass("active");
      }
    });
    var view = jQuery(this).data("view");
    var view_hidden = jQuery(this).data("view-hidden");
    console.log(view);
    jQuery(this).toggleClass("active");
    jQuery("#" + view).toggleClass("hidden");
    jQuery("#" + view_hidden).toggleClass("hidden");
    mymap.invalidateSize();
  });
}
