import { tns } from "tiny-slider/src/tiny-slider";
import LazyLoad from "vanilla-lazyload";
var lazyLoad = new LazyLoad();
function sliderLazy() {
  lazyLoad.update();
}
export default function sliderLogementsCol() {
  var slider_logements = document.getElementById("slider-logements-colonne");
  if (slider_logements !== null) {
    var slides = jQuery(".slider-logements-colonne").data("slides");
    console.log(slides);
    var sliderLogements = tns({
      container: ".slider-logements-colonne",
      slideBy: "page",
      items: 1,
      loop: true,
      autoplay: false,
      autoplayButtonOutput: false,
      controls: true,
      nav: false,
      center: false,
      controlsContainer: ".controls-logements-colonne",
    });
    sliderLogements.on("transitionEnd", sliderLazy);
  }
}
