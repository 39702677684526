import LazyLoad from "vanilla-lazyload";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

const lazyLoad = new LazyLoad();

function updateUI(item) {
  const sizes = item.parent().parent().parent().find(".size-choice");
  const tailles =
    item
      .parent()
      .parent()
      .find(".color-active")
      .data("sizes")
      .toString()
      .split(",")
      .map(Number) || [];

  for (let index = 0; index < sizes.length; index++) {
    const size = sizes[index];
    if (tailles.length !== 0 && !tailles.includes(jQuery(size).data("size"))) {
      jQuery(size).addClass("size-disabled");
      jQuery(size).removeClass("size-active");
    } else {
      jQuery(size).removeClass("size-disabled");
    }
  }
}

function selectColor() {
  jQuery("body").on("click", ".color-choice", function () {
    var id = jQuery(this).data("id");
    jQuery(this).parent().find(".color-choice").removeClass("color-active");
    jQuery(this).addClass("color-active");
    updateUI(jQuery(this));
    if (
      jQuery("#produit-" + id).find(".tailles").length === 0 ||
      jQuery("#produit-" + id).find(".size-active").length > 0
    ) {
      var selection = {
        id: id,
        color: jQuery(this).data("color"),
        size:
          jQuery("#produit-" + id)
            .find(".size-active")
            .data("size") || 0,
      };
      jQuery(".js-add-product-disabled[data-id=" + id + "]").removeClass(
        "js-add-product-disabled"
      );
      ajaxUI(selection);
    } else {
      jQuery(".js-add-product[data-id=" + id + "]").addClass(
        "js-add-product-disabled"
      );
    }
  });
}

function selectSize() {
  jQuery("body").on("click", ".size-choice:not(.size-disabled)", function () {
    var id = jQuery(this).data("id");
    if (
      jQuery("#produit-" + id).find(".couleurs").length === 0 ||
      jQuery("#produit-" + id).find(".color-active").length > 0
    ) {
      var selection = {
        id: id,
        color:
          jQuery("#produit-" + id)
            .find(".color-active")
            .data("color") || 0,
        size: jQuery(this).data("size"),
      };
      ajaxUI(selection);
      jQuery(".js-add-product-disabled[data-id=" + id + "]").removeClass(
        "js-add-product-disabled"
      );
    } else {
      jQuery(".js-add-product[data-id=" + id + "]").addClass(
        "js-add-product-disabled"
      );
    }
    jQuery(this).parent().find(".size-choice").removeClass("size-active");
    jQuery(this).addClass("size-active");
  });
}

function ajaxUI(produit) {
  jQuery.ajax({
    url: window.origin + "/wp/wp-admin/admin-ajax.php",
    data: {
      action: "update_ui_produit",
      selection: produit,
    },
    type: "POST",
    beforeSend: function () {
      jQuery(
        ".js-add-product[data-id=" + produit.id + "] .animate-spin"
      ).toggleClass("hidden");
    },
    success: function (data) {
      if (
        !jQuery("#prix_" + data.id)
          .parent()
          .hasClass("hidden")
      ) {
        jQuery("#prix_" + data.id).html(data.prix);
        jQuery("#prix_" + data.id).data("prix", data.prix);
        jQuery("#prix_" + data.id)
          .parent()
          .removeClass("hidden");
      }
      jQuery("#stock_" + data.id).html(data.stock);
      jQuery("#stock_" + data.id)
        .parent()
        .parent()
        .removeClass("hidden");
      jQuery(
        ".js-add-product[data-id=" + produit.id + "] .animate-spin"
      ).toggleClass("hidden");
    },
  });
  return false;
}

function ajax(params) {
  params.search = jQuery("#produits_input").val();
  const produits_container = jQuery(".liste-produits");
  jQuery.ajax({
    url: window.origin + "/wp-admin/admin-ajax.php",
    data: params,
    type: "POST",
    beforeSend: function () {
      produits_container.html("");
      jQuery(".loader").show();
    },
    success: function (data) {
      jQuery(".loader").hide();
      produits_container.html(data);
      selectColor();
      const lazyLoad = new LazyLoad();
    },
  });
  return false;
}

function updateTotal(panier) {
  let total = 0.0;

  panier.forEach((item) => {
    var prix = parseFloat(item.prix).toFixed(2);
    var quantite = parseFloat(item.quantite).toFixed(2);

    total += +prix * +quantite;
    console.log(prix * quantite);
  });

  jQuery("#js-panier-total").html(parseFloat(total).toFixed(2));
}

export function ajaxProduits() {
  const params = {
    action: "recherche_produits",
    cats: [],
    search: "",
    page: 1,
  };
  jQuery(".produits__form .select_cat").on("change", function () {
    params["categories"] = jQuery(this).val();
    params["cat"] = "-1";
    params["page"] = 1;
    jQuery(".start").val("");
    jQuery(".end").val("");
    ajax(params);
  });

  jQuery("body").on("click", ".js-change-page", function (e) {
    e.preventDefault();
    params["page"] = jQuery(this).data("page");
    jQuery("html,body").animate({
      scrollTop: jQuery(".produits__form").offset().top,
    });
    ajax(params);
  });

  let timer = null;
  jQuery(".produits__form #produits_input").on("keyup", function (e) {
    params["page"] = 1;
    clearTimeout(timer);
    timer = setTimeout(ajax, 500, params);
  });
}

export function dispatchPanier() {
  selectSize();
  selectColor();
  calculateTotalPanier();

  jQuery("body").on(
    "click",
    ".js-add-product:not(.js-add-product-disabled)",
    function (e) {
      e.preventDefault();
      let total_articles = 0;
      const id = jQuery(this).data("id");
      const title = jQuery(this).data("title");
      const quantite = parseInt(jQuery("#quantite_" + id).val());
      const prix =
        parseFloat(jQuery("#prix_" + id).data("prix")).toFixed(2) || 0;
      const max = parseInt(jQuery(this).data("max"));
      const enStock = parseInt(jQuery("#stock_" + id).html()) || 0;
      const color =
        jQuery(this).parent().parent().find(".color-active").data("color") || 0;
      const size =
        jQuery(this).parent().parent().find(".size-active").data("size") || 0;
      let panier = Cookies.get("panier");
      panier = panier ? JSON.parse(panier) : [];
      var inPanier = panier.filter((obj) => {
        return obj.id === id && obj.color === color && obj.size === size;
      });
      console.log(panier);
      if (inPanier.length > 0) {
        console.log(inPanier[0].quantite + quantite, quantite, max, enStock);
        if (inPanier[0].quantite + quantite > max) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Vous avez atteint la quantité maximum pour ce produit ",
          });
        } else if (enStock < inPanier[0].quantite + quantite) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "il n'y a plus de stock pour ce produit",
          });
        } else {
          inPanier[0].quantite =
            parseInt(inPanier[0].quantite) + parseInt(quantite);
          var index = panier
            .map(function (e) {
              return e.itemID;
            })
            .indexOf(inPanier[0].itemID);
          panier[index] = inPanier[0];

          Cookies.set("panier", JSON.stringify(panier));
          Swal.fire({
            icon: "success",
            title: "Produit ajouté",
            html: `Le produit <strong>${title}</strong> a été ajouté au panier`,
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
          });
        }
      } else {
        panier.push({
          id: id,
          quantite: quantite,
          prix: prix,
          color: color,
          size: size,
          itemID: Math.random().toString(36).substr(2, 9),
        });
        Cookies.set("panier", JSON.stringify(panier), { expires: 7 });
        Swal.fire({
          icon: "success",
          title: "Produit ajouté",
          html: `Le produit <strong>${title}</strong> a été ajouté au panier`,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
      for (let index = 0; index < panier.length; index++) {
        const item = panier[index];
        total_articles += parseInt(item.quantite);
      }
      jQuery(".js-panier-button span").html(total_articles);
    }
  );

  jQuery(".select-item").on("change", function (e) {
    const key = jQuery(this).data("key");

    let panier = Cookies.get("panier");
    panier = panier ? JSON.parse(panier) : [];
    var inPanier = panier.filter((obj) => {
      return obj.itemID === key;
    });
    inPanier[0].quantite = parseInt(jQuery(this).val());
    var index = panier
      .map(function (e) {
        return e.key;
      })
      .indexOf(key);
    panier[index] = inPanier[0];
    Cookies.set("panier", JSON.stringify(panier));
    var total = parseFloat(panier[index].prix * panier[index].quantite).toFixed(
      2
    );

    jQuery("li[data-key=" + key + "]")
      .find(".js-prix")
      .html(total);
    updateTotal(panier);
    // calculateTotalPanier();
  });

  jQuery("body").on("click", " #js-add-commande:not(.disabled)", function (e) {
    e.preventDefault();
    let panier = Cookies.get("panier");
    panier = panier ? JSON.parse(panier) : [];
    if (panier.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Votre panier est vide",
      });
    }
    if (panier.length > 0) {
      var commande = {
        action: "dispatch_commande",
        commande: panier,
        total: jQuery("#js-panier-total").html(),
        status: "add",
      };
      jQuery.ajax({
        url: window.origin + "/wp-admin/admin-ajax.php",
        data: commande,
        type: "POST",
        beforeSend: function () {
          jQuery(".loader").show();
          Swal.fire({
            icon: "info",
            title: "Veuillez patienter",
            text: "Votre commande est en cours de traitement",
            showConfirmButton: false,
          });
        },
        success: function (data) {
          jQuery(".loader").hide();
          if (data.success) {
            Cookies.remove("panier");
            jQuery(".js-panier-button span").html(0);
            Swal.fire({
              icon: "success",
              title: "Commande envoyée",
              html: "Votre commande a été envoyée, </br> vous allez recevoir un email de confirmation <br> avec le bon de commande <br> et les informations de retraite",
              showConfirmButton: true,
              confirmButtonText: "C'est compris",
            }).then((result) => {
              jQuery("#panier-liste, .panier-empty").toggleClass("hidden");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: data.data,
            });
          }
        },
      });
    }
  });

  jQuery(".delete_produit").on("click", function (e) {
    e.preventDefault();
    const title = jQuery(this).data("title");
    let panier = Cookies.get("panier");
    const key = jQuery(this).data("key");
    panier = panier ? JSON.parse(panier) : [];
    Swal.fire({
      title: "Êtes-vous sûr ?",
      html: `Voulez-vous supprimer le produit <br> <strong>${title}</strong> du panier ?`,
      showDenyButton: true,
      confirmButtonText: `Oui`,
      denyButtonText: `Non`,
    }).then((result) => {
      if (result.isConfirmed) {
        panier = panier.filter((obj) => {
          console.log(obj.itemID, key);
          return obj.itemID !== key;
        });
        Cookies.set("panier", JSON.stringify(panier));
        jQuery("li[data-key=" + key + "]").fadeOut();
        jQuery("li[data-key=" + key + "]").remove();
        if (panier.length === 0) {
          jQuery("#panier-liste, .panier-empty").toggleClass("hidden");
        }
        updateTotal(panier);
        let total_articles = 0;
        for (let index = 0; index < panier.length; index++) {
          const item = panier[index];
          total_articles += parseInt(item.quantite);
        }
        jQuery(".js-panier-button span").html(total_articles);
        Swal.fire("Produit enlevé!", "", "success");
        calculateTotalPanier();
      }
    });
  });
}

export function mesCommandes() {
  jQuery(".cancel-commande").on("click", function (e) {
    e.preventDefault();
    const id = jQuery(this).data("cancel");
    Swal.fire({
      title: "Êtes-vous sûr ?",
      html: "Voulez-vous annuler la commande ?",
      showDenyButton: true,
      confirmButtonText: `Oui`,
      denyButtonText: `Non`,
    }).then((result) => {
      if (result.isConfirmed) {
        var commande = {
          action: "dispatch_commande",
          commande_id: id,
          status: "cancel",
        };
        jQuery.ajax({
          url: window.origin + "/wp-admin/admin-ajax.php",
          data: commande,
          type: "POST",
          beforeSend: function () {
            jQuery(".loader").show();
            Swal.fire({
              icon: "info",
              title: "Veuillez patienter",
              text: "Votre commande est en cours de traitement",
              showConfirmButton: false,
            });
          },
          success: function (data) {
            jQuery(".loader").hide();
            if (data.success) {
              Swal.fire({
                icon: "success",
                title: "Commande annulée",
                html: "Votre commande a été annulée",
                showConfirmButton: true,
                confirmButtonText: "C'est compris",
              }).then((result) => {
                location.reload();
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: data.data,
              });
            }
          },
        });
      }
    });
  });
}

function calculateTotalPanier() {
  var solde = parseFloat(jQuery(".js-panier-button").data("solde")).toFixed(2);
  var total = parseInt("0");
  var items = document.querySelectorAll(".js-prix");
  items.forEach((item) => {
    var prix = +parseFloat(item.dataset.prix).toFixed(2);
    total += prix;
  });
  total = parseFloat(total).toFixed(2);
  jQuery("#js-panier-total").html(total);
  if (+total > +solde) {
    jQuery("#js-add-commande").addClass("disabled");
    jQuery("#no-solde").removeClass("hidden");
  } else {
    jQuery("#js-add-commande").removeClass("disabled");
    jQuery("#no-solde").addClass("hidden");
  }
}
