import { tns } from "tiny-slider/src/tiny-slider";
import { Fancybox } from "@fancyapps/ui";
import tableSort from "table-sort-js/table-sort.js";
import LazyLoad from "vanilla-lazyload";
import init from "./common";
import { Tabs } from "./utils/tabs";
import { ajaxArticles } from "./ajax/ajax-articles";
// import { ajaxDocs } from "./ajax/ajax-docs";
import sliderLogementsCol from "./slider-logements-colonne";
import { formInit } from "./logements-filter";
import homeAgenda from "./home-agenda";
import initMap from "./osm";
import singleCarto from "./single-carto";
import Swal from "sweetalert2";
import {
    ajaxProduits,
    dispatchPanier,
    mesCommandes,
} from "./gestion-stocks-public";

jQuery(function () {
    init();
    // Init Lazy load instance
    var lazyLoad = new LazyLoad();

    var tabs_container = document.getElementById("tabs");
    if (tabs_container !== null) {
        const tabs = new Tabs({
            elem: "tabs",
            open: 0,
        });
    }

    if (jQuery("body").hasClass("site-3")) {
        homeAgenda();
    }

    if (jQuery(".archive__form").length > 0) {
        ajaxArticles();
    }
    if (jQuery(".produits__form").length > 0) {
        ajaxProduits();
        dispatchPanier();
    }

    if (jQuery(".mes-commandes").length > 0) {
        mesCommandes();
    }

    if (jQuery(".panier").length > 0) {
        dispatchPanier();
    }
    function search_doc(val) {
        jQuery(".documents-joints__item-title").each(function (e) {
            var value = val.toLocaleLowerCase();
            var title = jQuery(this)
                .text()
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "");
            var accents = jQuery(this).text().toLocaleLowerCase();
            if (title.includes(value) || accents.includes(value)) {
                jQuery(this).parent().parent().removeClass("hidden");
            } else {
                jQuery(this).parent().parent().addClass("hidden");
            }
        });
    }
    let timer = null;
    jQuery("#docs_input").on("keyup", function (e) {
        clearTimeout(timer);
        timer = setTimeout(search_doc, 500, jQuery(this).val());
    });
    if (jQuery("#slider-logements-colonne").length > 0) {
        sliderLogementsCol();
    }

    jQuery("#js-show-replies").on("click", function () {
        console.log("click");
        jQuery(this).parent().parent().find(".replies").slideToggle();
    });
    jQuery(".js-delete-post").on("click", function () {
        var id = jQuery(this).data("id");
        console.log("delete " + id);
        alert("Voulez vous vraiment supprimer ce commentaire ?");
        jQuery.ajax({
            url: window.origin + "/wp/wp-admin/admin-ajax.php",
            data: {
                action: "delete_post",
                id: id,
            },
            type: "POST",
            success: function (data) {
                console.log(data);
                if (data === true) {
                    alert("Commentaire supprimé");
                    jQuery("#comment-" + id).remove();
                } else {
                    alert("Erreur lors de la suppression");
                }
            },
        });
        return false;
    });

    var replies = jQuery(".comment-reply-link");
    replies.each(function (index, el) {
        jQuery(this).html(jQuery(this).data("replyto"));
    });

    function sliderLazy() {
        lazyLoad.update();
    }
    var slider_actus = document.getElementById("slider-actus");
    if (slider_actus !== null) {
        var sliderAgenda = tns({
            container: ".slider-actus",
            items: 1,
            loop: true,
            autoplay: true,
            autoplayButtonOutput: false,
            controls: true,
            autoHeight: false,
            nav: false,
            center: false,
            controlsContainer: ".slider-actus-control",
        });
        sliderAgenda.events.on("transitionEnd", sliderLazy);
    }
    var slider_photos = document.getElementById("slider-photos");
    if (slider_photos !== null) {
        var slider_photos = tns({
            container: ".slider-photos",
            items: 1,
            loop: false,
            autoplay: false,
            autoplayButtonOutput: false,
            controls: true,
            navAsThumbnails: true,
            nav: true,
            navContainer: "#thumbnails",
            navAsThumbnails: true,
            center: false,
            controlsContainer: ".controls-photos",
        });
        slider_photos.events.on("transitionEnd", sliderLazy);
    }

    // Init Galerie
    Fancybox.bind("[data-fancybox='galerie']", {
        // Your options go here
        thumbs: {
            autoStart: true,
        },
    });
});

if (jQuery("body").hasClass("page-template-offres")) {
    console.log("form");
    formInit();
    initMap("single-carte");
}
if (jQuery("body").hasClass("single-logements")) {
    singleCarto();
}

var carte_patrimoine = jQuery("#carte-patrimoine").length > 0;
console.log(carte_patrimoine);

if (carte_patrimoine) {
    initMap("carte-patrimoine");
    jQuery("#docs_input").on("keyup", function (e) {
        // hide posts that title not match
        jQuery(".post-list-children > div").each(function (e) {
            var value = jQuery(this)
                .find(".text-2xl")
                .text()
                .toLocaleLowerCase();
            var title = jQuery("#docs_input").val().toLocaleLowerCase();
            if (value.includes(title)) {
                jQuery(this).removeClass("hidden");
            } else {
                jQuery(this).addClass("hidden");
            }
        });
        //add inactive class to marker with data-title not match
        jQuery(".markers > div").each(function (e) {
            var value = jQuery(this).data("title").toLocaleLowerCase();
            var id = jQuery(this).data("id");
            var title = jQuery("#docs_input").val().toLocaleLowerCase();
            if (value.includes(title)) {
                jQuery(this).removeClass("inactive");
            } else {
                jQuery(this).addClass("inactive");
            }

            // hide .marker-{id}
            jQuery(".marker-" + id).each(function (e) {
                if (value.includes(title)) {
                    jQuery(this).removeClass("hidden");
                } else {
                    jQuery(this).addClass("hidden");
                }
            });
        });
    });

    jQuery(".select_cat").on("change", function () {
        var slug = jQuery(this).val();
        var id = jQuery(this).data("id");
        jQuery(".post-list-children > div").each(function (e) {
            var commune = jQuery(this)
                .find(".patrimoine-commune")
                .text()
                .toLowerCase();
            commune = commune.split(" ").pop();
            // commune  =  73200 albertville, get only albertville

            if (slug === "0") {
                jQuery(this).removeClass("hidden");
                jQuery(".marker-" + id).removeClass("hidden");
            } else {
                if (commune === slug) {
                    jQuery(this).removeClass("hidden");
                    jQuery(".marker-" + id).removeClass("hidden");
                } else {
                    jQuery(this).addClass("hidden");
                    jQuery(".marker-" + id).addClass("hidden");
                }
            }
        });
        jQuery(".markers > div").each(function (e) {
            var commune = jQuery(this)
                .find(".patrimoine-commune")
                .text()
                .toLowerCase();
            commune = commune.split(" ").pop();
            console.log(commune, slug);
            if (commune === slug) {
                jQuery(this).removeClass("inactive");
            } else {
                jQuery(this).addClass("inactive");
            }

            // hide .marker-{id}
            jQuery(".marker-" + id).each(function (e) {
                if (commune === slug) {
                    jQuery(this).removeClass("hidden");
                } else {
                    jQuery(this).addClass("hidden");
                }
            });
        });
    });
}
if (jQuery("body").hasClass("single-patrimoine")) {
    initMap("carte-patrimoine");
}

jQuery(".delete-annonce").on("click", function (e) {
    e.preventDefault();
    var id = jQuery("#_acf_post_id").val();
    console.log(id);
    Swal.fire({
        title: "Êtes-vous sûr ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler",
    }).then((result) => {
        if (result.isConfirmed) {
            jQuery.ajax({
                url: window.origin + "/wp/wp-admin/admin-ajax.php",
                data: {
                    action: "delete_annonce",
                    id: id,
                },
                type: "POST",
                success: function (data) {
                    console.log(data);
                    if (data !== false) {
                        Swal.fire(
                            "Supprimé !",
                            "Votre annonce a été supprimée.",
                            "success",
                        );
                        setTimeout(function () {
                            window.location.href =
                                window.origin + "/le-coin-gaia/";
                        }, 2000);
                    } else {
                        Swal.fire(
                            "Erreur",
                            "Une erreur est survenue.",
                            "error",
                        );
                    }
                },
            });
        }
    });
});
